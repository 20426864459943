<template>
  <b-table
    :fields="fields"
    :items="items"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    sticky-header="400px"
    responsive="xl"
    striped
    hover
  >
    <template #cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <!-- <b-dropdown-item @click="registrarFrequencia(item.item)">
                        {{ $t("PAINEL_PROFESSOR.REGISTRAR_FREQUENCIAS")}}
                    </b-dropdown-item> -->
          <b-dropdown-item @click="editarAula(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluirAula(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'data',
          label: 'Data',
          sortable: true,
          formatter: (value) => {
            return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'descricao',
          label: 'Aula',
          sortable: true,
        },
        {
          key: 'conteudo',
          label: 'Conteúdo',
          sortable: true,
          formatter: (value) => {
            return value ? `${value.substring(0, 25)}...` : '';
          },
        },
        {
          sortable: true,
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    registrarFrequencia(item) {
      this.$emit('registrar-frequencia', 'aulas', 'frequencia', item);
    },
    editarAula(item) {
      this.$emit('editar-aula', item);
    },
    excluirAula(item) {
      this.$emit('excluir-aula', item);
    },
  },
};
</script>
