<template>
  <div>
    <div class="row my-4">
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataAvaliacao"
          v-model="form.dataAvaliacao"
          :label="$t('GERAL.DATA')"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-select-search
          v-model="form.tipoAvaliacaoId"
          :label="$t(`GERAL.TIPO`)"
          :options="opcoes.tipos"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          ref="descricao"
          v-model="form.descricao"
          type="text"
          :label="$t('GERAL.DESCRICAO')"
          :placeholder="$t('GERAL.DESCRICAO')"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          ref="conteudo"
          v-model="form.conteudo"
          type="text"
          :label="$t('GERAL.CONTEUDO')"
          :placeholder="$t('GERAL.CONTEUDO')"
        />
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <b-button @click="cancelar" variant="secondary" class="mr-2">
        {{ $t('GERAL.CANCELAR') }}
      </b-button>
      <b-button @click="salvar" variant="primary">
        {{ $t('GERAL.SALVAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services & Models:
import AvaliacoesService from '@/common/services/academico/avaliacoes.service.js';
import TipoAvaliacaoService from '@/common/services/academico/tipoAvaliacao.service.js';

// Components:
import {
  InputText,
  InputSelectSearch,
  InputDateOnlyBtn
} from '@/components/inputs';

export default {
  props: {
    dadosGerais: { type: Object, default: Object },
    filtro: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelectSearch,
    InputDateOnlyBtn,
  },
  data() {
    return {
      opcoes: {
        tipos: [],
      },
      form: {},
    };
  },
  mounted() {
    this.getTipos();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DADOS:
    getTipos() {
      this.$store.dispatch(START_LOADING);
      TipoAvaliacaoService.obterPorTipoDeMediaDisciplina(this.dadosGerais.disciplinaId, this.dadosGerais.turmaId, this.filtro.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    cancelar() {
      this.$emit('cancelar');
    },
    salvar() {
      this.form.segmentacaoId = this.dadosGerais.segmentacaoId;
      this.form.turmaId = this.dadosGerais.turmaId;
      this.form.disciplinaId = this.dadosGerais.disciplinaId;

      if (this.form.dataRecuperacao < this.form.dataAvaliacao) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('PAINEL_PROFESSOR.DATA_RECUPERACAO_INVALIDA')
        );
        return;
      }
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PAINEL_PROFESSOR.AVALIACAO_CRIADA')
          );
          this.cancelar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
