<template>
  <b-table
    :items="form"
    :fields="campos"
    ref="selectableTable"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    sticky-header="400px"
    head-variant="light"
    responsive
    hover
  >
    <template #cell(valor)="item">
      <template>
        <b-input-group class="">
          <input-number
            ref="valor"
            v-model="item.item.valor"
            :min="0"
            :maxLength="1"
            decimal
          />
        </b-input-group>
      </template>
    </template>
  </b-table>
</template>
<script>
// Components:
import { InputNumber } from '@/components/inputs';

export default {
  components: {
    InputNumber,
  },
  props: {
    form: { type: Array, default: Array },
  },
  watch: {
    form: {
      handler() {
        this.$emit('atualizado', this.form);
      },
      deep: true,
    },
  },
  data() {
    return {
      campos: [
        {
          key: 'nomeAluno',
          sortable: true,
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.ALUNO'),
        },
        {
          key: 'valor',
          sortable: true,
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.PONTOS'),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style>
td .input-group {
  height: auto !important;
  margin: 0px !important;
}

table td,
table th,
.table td,
.table th {
  padding: 0px 12px;
}
</style>
