<template>
  <div v-if="exibir">
    <aula-form
      :form="dadosAula"
      @voltar="voltar"
      @salvar="salvar"
      @refs="capturarRefs"
    />
    <div class="mt-4">
      <span>Preencher frequência </span>
      <tabela-frequencia :items="frequencias" />
    </div>

    <hr />
    <div class="d-flex justify-content-between mt-4">
      <b-button @click="voltar" variant="secondary" class="mr-2">{{
        $t('GERAL.VOLTAR')
      }}</b-button>
      <b-button @click="salvar" variant="primary">{{
        $t('GERAL.SALVAR')
      }}</b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services & Models:
import AulasService from '@/common/services/academico/aulas.service.js';
import FrequenciaService from '@/common/services/academico/frequencia.service.js';

// Components:
import AulaForm from './Form';
import TabelaFrequencia from '../TabelaFrequencia';
import Frequencia from '@/common/models/frequencia/Frequencia';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    dadosGerais: { type: Object, default: Object },
    dadosAula: { type: Object, default: Object },
    dadosFrequencia: { type: Array, default: Array },
    anoLetivoId: { type: String, default: String },
  },
  components: {
    AulaForm,
    TabelaFrequencia,
  },
  watch: {
    exibir: 'obterFrequencias',
  },
  data() {
    return {
      frequencia: new Frequencia({}),
      frequencias: [],
      frequenciasAtualizadas: [],
      refs: [],
    };
  },
  methods: {
    voltar() {
      this.$emit('voltar');
    },
    salvar() {
      this.dadosAula.id ? this.editar() : this.criarComFrequencia();
    },

    criarComFrequencia() {
      this.dadosAula.turmaId = this.dadosGerais.turmaId;
      this.dadosAula.disciplinaId = this.dadosGerais.disciplinaId;
      this.dadosAula.segmentacaoId = this.dadosGerais.segmentacaoId;

      const checarValidacaoObservacao = this.frequencias.find(
        (x) => x.faltaJustificada === true && x.observacao.length < 1
      );

      if (checarValidacaoObservacao !== undefined) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t(
            'Observação não preenchida em campo onde falta justificada foi marcada'
          )
        );
        return;
      }

      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      AulasService.criarComFrequencia({
        ...this.dadosAula,
        frequencias: this.frequencias,
      })
        .then(() => {
          this.$emit('atualizar-tabela');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PAINEL_PROFESSOR.AULA_CRIADA')
          );
          this.voltar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    obterFrequencias() {
      this.dadosGerais.anoLetivoId = this.anoLetivoId;
      this.dadosGerais.aulaId =
        this.dadosAula.id === undefined ? '' : this.dadosAula.id;

      this.$store.dispatch(START_LOADING);
      FrequenciaService.obterPorFiltro(this.dadosGerais)
        .then(({ data }) => {
          this.frequencias = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editar() {
      const checarValidacaoObservacao = this.frequencias.find(
        (x) => x.faltaJustificada === true && x.observacao.length < 1
      );

      if (checarValidacaoObservacao !== undefined) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t(
            'Observação não preenchida em campo onde falta justificada foi marcada'
          )
        );
        return;
      }
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      AulasService.editar({
        ...this.dadosAula,
        frequencias: this.frequencias,
      })
        .then(() => {
          this.$emit('atualizar-tabela');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PAINEL_PROFESSOR.AULA_EDITADA')
          );
          this.voltar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
