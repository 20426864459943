<template>
  <modal
    id="modalPontosAdicionais"
    :exibir="exibir"
    :titulo="`Pontos adicionais`"
    :campos="$refs"
    :tamanho="'lg'"
    @fechar="fecharModal"
  >
    <tabela-pontos-adicionais
      :form="dadosPontosAdicionais"
      @atualizado="getDados"
    />

    <!-- Footer do modal -->
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button class="mr-2" variant="secondary" @click="fecharModal">{{
          $t('GERAL.FECHAR')
        }}</b-button>
        <b-button variant="primary" @click="salvarAtualizar"
          >Salvar pontos</b-button
        >
      </div>
    </template>
  </modal>
</template>
<script>
// Components
import Modal from '@/components/modal/Modal.vue';
import TabelaPontosAdicionais from '../pontos-adicionais/TabelaPontosAdicionais.vue';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import PainelProfessorService from '@/common/services/painel-professor/painel-professor.js';

export default {
  components: {
    Modal,
    TabelaPontosAdicionais,
  },
  watch: {
    exibir: 'listarPontosAdicionais',
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  data() {
    return {
      dadosPontosAdicionais: [],
      pontosAdicionaisAtualizados: [],
    };
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    listarPontosAdicionais() {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.listarPontosAdicionais(this.form)
        .then(({ data }) => {
          this.dadosPontosAdicionais = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getDados(pontosAdicionais) {
      this.pontosAdicionaisAtualizados = pontosAdicionais;
    },
    // FUNÇÕES CRUD:
    salvarAtualizar() {
      let padraoNuloId = '00000000-0000-0000-0000-000000000000';

      const criacao = [];
      const atualizar = [];

      this.pontosAdicionaisAtualizados.forEach((item) => {
        item.id === padraoNuloId ? criacao.push(item) : atualizar.push(item);
      });

      criacao.length ? this.criar(criacao) : {};
      atualizar.length ? this.atualizar(atualizar) : {};
    },
    criar(form) {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.criarListaPontosAdicionais(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Pontos atualizados com sucesso')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    atualizar(form) {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.atualizarListaPontosAdicionais(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Pontos atualizados com sucesso')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
