<template>
  <b-table
    :fields="tabela.fields"
    :items="items"
    class="position"
    show-empty
    sticky-header="400px"
    responsive="xl"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    striped
  >
    <template #cell(valorAvaliacao)="item">
      <template>
        <div>
          <b-input-group class="mt-3">
            <div class="col-xl-12 flex">
              <input-number
                class="mr-2 mb-2"
                ref="valorAvaliacao"
                v-model="item.item.valorAvaliacao"
                :min="0"
                :max="10"
                :maxLength="10"
                size="sm"
              />
            </div>
          </b-input-group>
        </div>
      </template>
    </template>
    <template #cell(valorRecuperacao)="item">
      <template>
        <div>
          <b-input-group class="mt-3">
            <div class="col-xl-12 flex">
              <input-number
                class="mr-2 mb-2"
                ref="valorRecuperacao"
                v-model="item.item.valorRecuperacao"
                :min="0"
                :max="10"
                :maxLength="10"
                size="sm"
                :disabled="habilitarOuDesabilitarPorMeioDoTipoAvaliacaoCodigo"
              />
            </div>
          </b-input-group>
        </div>
      </template>
    </template>

    <template #cell(observacao)="item">
      <template>
        <div>
          <b-input-group class="mt-3">
            <div class="col-12 flex-text-area">
              <input-text
                class="input mb-2"
                ref="observacao"
                v-model="item.item.observacao"
                size="sm"
              />
            </div>
          </b-input-group>
        </div>
      </template>
    </template>
  </b-table>
</template>
<script>
import { InputNumber, InputText } from '@/components/inputs';

export default {
  props: {
    items: { type: Array, default: Array },
    tipoAvaliacaoCodigo: { type: Number, default: Number },
  },
  components: {
    InputNumber,
    InputText,
  },
  computed: {
    habilitarOuDesabilitarPorMeioDoTipoAvaliacaoCodigo() {
      return this.tipoAvaliacaoCodigo === 359 ||
        this.tipoAvaliacaoCodigo === 522
        ? true
        : false;
    },
  },
  watch: {
    items: {
      handler() {
        this.$emit('atualizado', this.items);
      },
      deep: true,
    },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'nomeAluno',
            label: 'Aluno',
            sortable: true,
          },
          {
            key: 'valorAvaliacao',
            label: 'Nota',
            sortable: true,
          },
          {
            key: 'valorRecuperacao',
            label: 'Recuperação',
            sortable: true,
          },
          {
            key: 'observacao',
            label: 'Observações',
            sortable: true,
          },
        ],
      },
    };
  },
  methods: {},
};
</script>

<style>
.position thead {
  position: relative;
  z-index: 300;
}
.position th {
  width: 800px;
  padding: 10px;
}
.position th div {
  text-align: left;
  padding-left: 10px;
}
.position td {
  width: 800px;
  padding: 0px 5px;
}
.margin-top {
  margin-top: 7px;
}
.flex {
  display: flex;
}
.flex-text-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
</style>
