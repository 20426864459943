import ApiService from "@/common/api/api.service";

let apiBasePath = 'Disciplina'
const DisciplinasService = {
  async criar (form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async criarTurmaDisciplina (form) {
    let { data } = await ApiService.post(`Turma/disciplina/adicionar`, form);
    return data;
  },
  async editar (form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async listar () {
    let { data } = await ApiService.get(`${apiBasePath}`);
    return data;
  },
  async excluir (disciplinaId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${disciplinaId}`);
    return data;
  },
  async excluirTurmaDisciplina ({ id, turmaId }) {
    let { data } = await ApiService.delete(`Turma/disciplina/remover?Id=${id}&TurmaId=${turmaId}`);
    return data;
  },
  async obterTodasSemPaginacao() {
    let { data } = await ApiService.get(`${apiBasePath}`);
    return data;
  },
  async obterPorFiltro (paginacao, filtro) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
      {
        params: { ...paginacao, Expressao: filtro },
      }
    );

    return data;
  },
  async buscarPorTurmaId (turmaId) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-por-turma/${turmaId}`);
    return data;
  },
  async buscarDisciplinaComAnoLetivoDocente (disciplinaId) {
    let { data } = await ApiService.get(`${apiBasePath}/listar-ano-docentes`, { params: { disciplinaId } });
    return data;
  },

  async realizarImpressaoRelatorioDisciplinas(params) {
    let { data } = await ApiService.get(`${apiBasePath}/imprimir-relatorio-disciplina`, {
      params: { turmaId: params.turmaId, grupoTurmaId: params.grupoTurmaId, anoLetivoId: params.anoLetivoId }
    });
    return data;
  },
}

export default DisciplinasService;