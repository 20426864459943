var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"empty-text":_vm.$t('GERAL.NENHUM_REGISTRO'),"head-variant":"light","fields":_vm.tabela.fields,"items":_vm.items,"show-empty":"","striped":"","hover":""},scopedSlots:_vm._u([{key:"cell(acoes)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('b-dropdown',{attrs:{"variant":"outline-default"}},[(!_vm.somenteTurma(item.item))?_c('b-dropdown-group',{attrs:{"id":"dropdown-turmas","header":"Somente para turmas:"}},[_c('b-dropdown-item',{attrs:{"disabled":_vm.somenteTurma(item.item)},on:{"click":function($event){return _vm.parecerDescritivo(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_PARECER_DESCRITIVO'))+" ")])],1):_vm._e(),(!_vm.temDisciplina(item.item))?_c('b-dropdown-group',{attrs:{"id":"dropdown-disciplinas","header":"Somente para disciplinas:"}},[_c('b-dropdown-item',{attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.frequencia(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_FREQUENCIA'))+" ")]),_c('b-dropdown-item',{attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.nota(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_NOTA'))+" ")]),_c('b-dropdown-item',{attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.recuperacaoSegmentacao(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RECUPERACAO_SEGMENTACAO'))+" ")]),_c('b-dropdown-item',{attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.pontosAdicionais(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_PONTO_ADICIONAL'))+" ")]),_c('b-dropdown-text',{staticClass:"title-dropdown",on:{"click":_vm.abriSubDropdownFrequencia}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_REGISTRO_FREQUENCIA'))+" "),_c('img',{class:[
                    'dropdown-arrow',
                    {
                      'dropdown-arrow-reverse':
                        _vm.exibirSubDropDown.abrirFrequencia,
                    },
                  ],attrs:{"src":require("@/assets/images/arrow.svg"),"alt":"dropdown"}})])]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirFrequencia),expression:"exibirSubDropDown.abrirFrequencia"}],staticClass:"title-dropdown-sub",on:{"click":function($event){return _vm.imprimirRegistroFrequencia(item.item, true)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_SEM_DADOS'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirFrequencia),expression:"exibirSubDropDown.abrirFrequencia"}],staticClass:"title-dropdown-sub",on:{"click":function($event){return _vm.imprimirRegistroFrequencia(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_COM_DADOS'))+" ")]),_c('b-dropdown-text',{staticClass:"title-dropdown",on:{"click":_vm.abriSubDropdownAvaliacao}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_REGISTRO_AVALIACAO'))+" "),_c('img',{class:[
                    'dropdown-arrow',
                    {
                      'dropdown-arrow-reverse':
                        _vm.exibirSubDropDown.abrirAvaliacao,
                    },
                  ],attrs:{"src":require("@/assets/images/arrow.svg"),"alt":"dropdown"}})])]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirAvaliacao),expression:"exibirSubDropDown.abrirAvaliacao"}],staticClass:"title-dropdown-sub",on:{"click":function($event){return _vm.imprimirRegistroAvaliacao(item.item, true)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_SEM_DADOS'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirAvaliacao),expression:"exibirSubDropDown.abrirAvaliacao"}],staticClass:"title-dropdown-sub",on:{"click":function($event){return _vm.imprimirRegistroAvaliacao(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_COM_DADOS'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.imprimirRelatorioFechamento(
                  item.item,
                  'listagem-notas-faltas'
                )}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_NOTAS_FALTAS'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.imprimirRelatorioFechamento(item.item, 'quadro-analitico')}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_QUADRO_ANALITICO'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.imprimirRelatorioFechamento(
                  item.item,
                  'quadro-analitico-segmentacao'
                )}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_QUADRO_ANALITICO_SEGMENTACAO'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){return _vm.imprimirRelatorioFechamento(
                  item.item,
                  'media-final-recuperacao'
                )}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO'))+" ")])],1):_vm._e()],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }