import ApiService from '@/common/api/api.service'

let apiBasePath = 'Aula'

const AulasService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async criarComFrequencia (form) {
        let { data } = await ApiService.post(`${apiBasePath}/com-frequencia`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (aulaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`)
        return data
    },
    // FUNÇÕES FILTRO:
    async obterPorFiltro (filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    SegmentacaoId: filtro.segmentacaoId,
                    TurmaId: filtro.turmaId,
                    DisciplinaId: filtro.disciplinaId,
                },
            }
        )
        return data
    }
}

export default AulasService