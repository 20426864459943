<template>
  <div v-if="exibir">
    <div class="mb-5">
      <p>
        Para registrar uma frequência, é necessário selecionar uma aula na
        tabela abaixo,
        <b>clicando no botão na coluna Ações</b> e selecionando a opção
        <b>Registrar frequências</b>.
      </p>
      <p>
        Caso não existe a aula que queira registrar frequência, você pode criar
        uma aula por aqui mesmo clicando no botão abaixo:
      </p>
      <b-button variant="primary" @click="criar">
        {{ $t('PAINEL_PROFESSOR.NOVA_AULA') }}
      </b-button>
    </div>

    <tabela-aulas
      :items="dados"
      class="tamanho"
      @registrar-frequencia="registrarFrequencia"
      @editar-aula="editar"
      @excluir-aula="excluir"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services & Models:
import AulasService from '@/common/services/academico/aulas.service.js';

// Components:
import TabelaAulas from '../aulas/Tabela.vue';

export default {
  props: {
    exibir: { type: Boolean, default: true },
    dados: { type: Array, default: Array },
  },
  components: {
    TabelaAulas,
  },
  methods: {
    // FUNÇÕES CRUD - FREQUÊNCIA:
    registrarFrequencia(keyHide, keyShow, item) {
      this.$emit('registrar-frequencia', keyHide, keyShow, item);
    },
    // FUNÇÕES CRUD - AULAS:
    criar() {
      this.$emit('criar-aula', 'aulas', 'formAula');
    },
    editar(item) {
      this.$emit('editar-aula', 'aulas', 'formAula', item);
    },
    excluir(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja excluir aula ?'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            AulasService.excluir(item.id)
              .then(() => {
                this.$emit('atualizar-tabela');
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('PAINEL_PROFESSOR.AULA_EXCLUIDA')
                );
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.tamanho{
  min-height: 110px;
}
</style>
