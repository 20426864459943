import ApiService from "@/common/api/api.service";

let apiBasePath = 'TipoAvaliacao'
const TipoAvaliacaoService = {
    async obterPorId(tipoAvaliacaoId) {
        let { data } = await ApiService.get(`${apiBasePath}/${tipoAvaliacaoId}`);
        return data;
    },
    async obterTodos() {
        let { data } = await ApiService.get(`${apiBasePath}/obter-todos`);
        return data;
    },
    async obterPorTipoDeMediaDisciplina(disciplinaId, turmaId, anoLetivoId) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-tipo-media`,
        {
            params: {
                TurmaId:turmaId,
                DisciplinaId:disciplinaId,
                AnoLetivoId:anoLetivoId
            },
        }
        );
        return data;
    },
    async obterTodosPorAnoLetivo({anoLetivoId}) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-anoLetivoId`, {params:{AnoLetivoId:anoLetivoId}});
        return data;
    },
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async excluir(aulaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`);
        return data;
    },
}

export default TipoAvaliacaoService;