<template>
  <modal
    :exibir="exibir"
    :titulo="'Frequência'"
    :campos="$refs"
    id="modalFrequencia"
    :tamanho="'xl'"
    :ocultarRodape="true"
    @fechar="fecharModal"
  >
    <configuracao-inicial
      :exibir="inicio"
      :dados="tabelas.dados.aulas"
      @criar-aula="openTabela"
      @editar-aula="openTabela"
      @atualizar-tabela="getAulas"
      @registrar-frequencia="registrarFrequencia"
    />

    <form-aula
      :exibir="formAula"
      :dadosGerais="form"
      :anoLetivoId="this.filtro.anoLetivoId"
      :dadosAula="tabelas.dados.formAula"
      :dadosFrequencia="tabelas.dados.frequencia"
      @voltar="configuracaoInicial"
    />
    <div v-if="tabelaFrequencia">
      <tabela-frequencia :items="tabelas.dados.frequencia" />
      <div class="d-flex w-100 justify-content-between">
        <b-button
          @click="configuracaoInicial"
          variant="secondary"
          class="mr-2"
          >{{ $t('GERAL.VOLTAR') }}</b-button
        >
        <b-button @click="salvarFrequencia" variant="primary">{{
          $t('GERAL.SALVAR')
        }}</b-button>
      </div>
    </div>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services & Models:
import AulasService from '@/common/services/academico/aulas.service.js';
import FrequenciaService from '@/common/services/academico/frequencia.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import TabelaFrequencia from './TabelaFrequencia';
import ConfiguracaoInicial from './configuracao-inicial/Index';
import FormAula from './aulas/Index';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    filtro: { type: Object, default: Object },
  },
  components: {
    Modal,
    TabelaFrequencia,
    ConfiguracaoInicial,
    FormAula,
  },
  data() {
    return {
      tabelas: {
        exibir: {
          aulas: true,
          formAula: false,
          frequencia: false,
        },
        dados: {
          aulas: [],
          formAula: {},
          frequencia: [],
        },
      },
      filtroParaFrequencia: {},
    };
  },
  watch: {
    exibir() {
      this.exibir ? this.configuracaoInicial() : {};
    },
  },
  computed: {
    inicio() {
      return this.tabelas.exibir.aulas;
    },
    tabelaFrequencia() {
      return this.tabelas.exibir.frequencia;
    },
    formAula() {
      return this.tabelas.exibir.formAula;
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getAulas() {
      this.$store.dispatch(START_LOADING);
      AulasService.obterPorFiltro(this.form)
        .then(({ data }) => {
          this.tabelas.dados.aulas = data.map((data) => ({
            ...data,
            data: data.data.replace(':00', ''),
          }));
          this.filtroParaFrequencia = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obterFrequencias(item) {
      let filtro = {
        turmaId: item.turmaId,
        anoLetivoId: this.filtro.anoLetivoId,
        cursoId: this.filtro.cursoId,
        disciplinaId: item.disciplinaId,
        aulaId: item.id,
      };

      this.$store.dispatch(START_LOADING);
      FrequenciaService.obterPorFiltro(filtro)
        .then(({ data }) => {
          this.tabelas.dados.frequencia = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.closeModal('aula');
        });
    },
    registrarFrequencia(keyHide, keyShow, item) {
      this.obterFrequencias(item);
      this.openTabela(keyHide, keyShow, item);
    },
    // FUNÇÕES MODAL
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES SUB-FUNÇÕES:
    configuracaoInicial() {
      this.tabelas.exibir.aulas = true;
      this.tabelas.exibir.formAula = false;
      this.tabelas.exibir.frequencia = false;
      this.getAulas();
    },
    openTabela(keyHide, keyShow, item) {
      // exibindo dados:
      this.tabelas.exibir[keyHide] = false;
      this.tabelas.exibir[keyShow] = true;
      // preenchendo dados:
      item
        ? (this.tabelas.dados[keyShow] = item)
        : (this.tabelas.dados[keyShow] = {});
    },
    // FUNÇÕES CRUD - FREQUÊNCIAS
    salvarFrequencia() {
      // TODO
    },
  },
};
</script>
