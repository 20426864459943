import ApiService from '@/common/api/api.service';

let apiBasePath = 'Turma';

const TurmasService = {
  // FUNÇÕES CRUD:
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir(turmaId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${turmaId}`);
    return data;
  },
  // FUNÇÕES FILTRO:
  async buscarPorId(turmaId) {
    let { data } = await ApiService.get(`${apiBasePath}/${turmaId}`);
    return data;
  },
  async buscarPorCursoId(cursoId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-por-curso/${cursoId}`
    );
    return data;
  },
  async buscarPorAnoLetivoId(anoLetivoId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-por-ano-letivo/${anoLetivoId}`
    );
    return data;
  },
  async buscarPorCursoIdGrupoTurmaId(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-por-curso-grupo`,
      {
        params: {
          grupoTurmaId: filtro.grupoTurmaId,
          cursoId: filtro.cursoId,
        },
      }
    );
    return data;
  },
  async buscarPorGrupoTurmaIdAnoLetivoId(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-por-grupo-turma-ano-letivo`,
      {
        params: {
          grupoTurmaId: filtro.grupoTurmaId,
          anoLetivoId: filtro.anoLetivoId,
        },
      }
    );
    return data;
  },
  async ObterPorCursoIdAnoLetivoId(cursoId, anoLetivoId) {
    const { data } = await ApiService.get(
      `${apiBasePath}/obter-por-curso-ano-letivo`,
      {
        params: {
          cursoId: cursoId,
          anoLetivoId: anoLetivoId,
        },
      }
    );
    return data;
  },
  async realizarImpressaoRelatorioTurmasParaAluno(params) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-turmas-para-aluno`,
      {
        params: {
          cursoId: params.cursoId,
          anoLetivoId: params.anoLetivoId,
          tipoContrato: params.tipoContrato,
        },
      }
    );
    return data;
  },
  async baixarRelatorioEtiqueta(params) {
    const { data } = await ApiService.get(`${apiBasePath}/imprimir-relatorio-etiquetas`, {
      params: { ...params },
    });
    return data;
  },
  async baixarRelatorioTurmas(params) {
    const { data } = await ApiService.get(`${apiBasePath}/imprimir-relatorio-turmas`, {
      params: { ...params },
    });
    return data;
  },
  async baixarRelatorioExcel(params) {
    const { data } = await ApiService.get(`${apiBasePath}/gerar-relatorio-excel-turmas`, {
      params: { ...params },
    });
    return data;
  },

  // GERENCIADOR DE TURMAS:
  async listarEnsalamento(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/listar-para-ensalamento`,
      {
        params: {
          anoLetivoId: filtro.anoLetivoId,
          grupoTurmaId: filtro.grupoTurmaId,
          cursoId: filtro.cursoId,
        },
      }
    );
    return data;
  },
  async enturmarAluno(aluno) {
    let { data } = await ApiService.put(`${apiBasePath}/enturmar-aluno`, aluno);
    return data;
  },
  async desenturmarAluno(aluno) {
    let desenturmar = {
      contratoId: aluno.contratoId,
    };
    let { data } = await ApiService.put(
      `${apiBasePath}/desenturmar-aluno`,
      desenturmar
    );
    return data;
  },
  async listarContratoPorTurmaId(turmaId) {
    const { data } = await ApiService.get(
      `${apiBasePath}/listar-contratos-por-turma/${turmaId}`
    );
    return data;
  },
  async obterTurmaPorGrupoTurma({ cursoId, grupoTurmaId }) {
    const { data } = await ApiService.get(
      `${apiBasePath}/obter-por-curso-grupo?cursoId=${cursoId}&grupoTurmaId=${grupoTurmaId}`
    );
    return data;
  },
  async gerarRelatorioTotalizadoresPdf(filtro) {
    const { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-totalizadores-periodo`,
      {
        params: {
          ...filtro,
        },
      }
    );
    return data;
  },
};

export default TurmasService;
