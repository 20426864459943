<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-3">
        <input-select-search
          ref="anoLetivoId"
          :label="$t(`ACADEMICO.ANO_LETIVO`)"
          :options="opcoes.anosLetivos"
          v-model="filtro.anoLetivoId"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="segmentacaoId"
          :label="$t(`ACADEMICO.SEGMENTACAO`)"
          :options="opcoes.segmentacoes"
          v-model="filtro.segmentacaoId"
          :isDisabled="desabilitar.segmentacao"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
          v-model="filtro.cursoId"
          :isDisabled="desabilitar.cursos"
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          v-model="filtro.turmaId"
          :isDisabled="desabilitar.turma"
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="disciplinaId"
          :label="$t(`ACADEMICO.DISCIPLINA`)"
          :options="opcoes.disciplinas"
          v-model="filtro.disciplinaId"
          :isDisabled="desabilitar.disciplina"
        />
      </div>
      <div class="col-12 col-md-auto align-items-center">
        <b-button
          variant="primary"
          class="botao-acao-filtro w-100"
          @click="filtrar"
        >
          {{ $t('GERAL.FILTRAR') }}
        </b-button>
      </div>
      <div class="col-12 col-md-auto">
        <b-button
          variant="secondary"
          class="botao-acao-filtro mt-3 w-100"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import DisciplinasService from '@/common/services/academico/disciplinas.service';

import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        anoLetivoId: null,
        segmentacaoId: '',
        cursoId: '',
        turmaId: '',
        disciplinaId: '',
      },
      desabilitar: {
        turma: true,
        disciplina: true,
        segmentacao: true,
        cursos: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        segmentacoes: [],
        turmas: [],
        disciplinas: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
    this.$emit('refs', this.$refs);
  },
  watch: {
    'filtro.turmaId'(turmaId) {
      if (turmaId) {
        this.getDisciplinas();
      }
    },
    'filtro.cursoId'(cursoId) {
      if (cursoId) {
        this.getTurmas();
      } else {
        this.desabilitar.turma = true;
        this.opcoes.turmas = [];
      }
      this.filtro.turmaId = null;
    },
    'filtro.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getSegmentacoes();
        this.getCursos(anoLetivoId);
      } else {
        this.opcoes.segmentacoes = [];
        this.opcoes.cursos = [];
        this.opcoes.turmas = [];
        this.desabilitar.segmentacao = true;
        this.desabilitar.cursos = true;
        this.desabilitar.turma = true;
      }
      this.filtro.segmentacaoId = null;
      this.filtro.cursoId = null;
      this.filtro.turmaId = null;
    },
  },
  methods: {
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          data.forEach((element) => {
            element.text = element.ano;
            element.value = element.id;
          });
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getSegmentacoes() {
      this.opcoes.anosLetivos.forEach((element) => {
        if (element.id == this.filtro.anoLetivoId) {
          this.opcoes.segmentacoes = element.segmentacoes;
          this.opcoes.segmentacoes.forEach((segmentacao) => {
            segmentacao.text = segmentacao.descricao;
            segmentacao.value = segmentacao.id;
          });
          this.desabilitar.segmentacao = false;
        }
      });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          this.desabilitar.cursos = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmas() {
      this.$store.dispatch(START_LOADING);
      TurmaService.ObterPorCursoIdAnoLetivoId(this.filtro.cursoId,this.filtro.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
          this.habilitarCampo('turma');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getDisciplinas() {
      this.$store.dispatch(START_LOADING);
      DisciplinasService.buscarPorTurmaId(this.filtro.turmaId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.disciplinas = data;
          this.habilitarCampo('disciplina');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', JSON.parse(JSON.stringify(this.filtro)));
    },
    limparFiltro() {
      this.filtro = {
        cursoId: '',
        segmentacaoId: '',
        turmaId: '',
        disciplinaId: '',
      };
      this.desabilitar.segmentacao = true;
      this.desabilitar.cursos = true;
      this.$emit('limparFiltro', { limpeza: true });
    },
  },
};
</script>
