<template>
  <b-table
    :fields="tabela.fields"
    :items="items"
    show-empty
    class="mt-3 position"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    sticky-header="250px"
    responsive="sm"
    head-variant="light"
    sort-by="aluno"
    :sort-desc="false"
    striped
    hover
  >
    <template #head(presente)="item">
      <div class="col-xl-12 flex">
        <input-checkbox
          :title="$t('GERAL.MARCAR_TODOS')"
          v-model="marcarTodasFrequencias"
          :aria-label="$t('GERAL.MARCAR_TODOS')"
          aria-describedby="checkbox-label"
          @change="marcarTodos"
          size="sm"
        />
        <span>{{ item.label }}</span>
      </div>
    </template>

    <template #cell(presente)="item">
      <template>
        <div>
          <div class="col-xl-12 flex ml-3">
            <input-checkbox
              v-model="item.item.presente"
              @change="desabilitarJustificativa(item)"
              size="sm"
            />
          </div>
        </div>
      </template>
    </template>

    <template #cell(faltaJustificada)="item">
      <template>
        <div>
          <div class="col-xl-12 flex">
            <input-checkbox
              v-model="item.item.faltaJustificada"
              @change="desabilitarPresenca(item)"
              size="sm"
            />
          </div>
        </div>
      </template>
    </template>

    <template #cell(observacao)="item">
      <template>
        <div>
          <input-text
            class="input mb-2"
            ref="observacao"
            v-model="item.item.observacao"
            :required="item.item.faltaJustificada"
            type="text"
            size="sm"
          />
        </div>
      </template>
    </template>
  </b-table>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';

export default {
  components: { InputCheckbox, InputText },
  props: {
    items: { type: Array, default: Array },
  },

  data() {
    return {
      marcarTodasFrequencias: false,
      tabela: {
        fields: [
          {
            key: 'aluno',
            label: 'Aluno',
            sortable: true,
          },
          {
            key: 'presente',
            label: 'Frequência',
            sortable: true,
          },
          {
            key: 'faltaJustificada',
            label: 'Falta justificada',
            sortable: true,
          },
          {
            key: 'observacao',
            label: 'Observações',
          },
        ],
      },
      faltaJustificadaIndex: '',
      faltasJustificadasCheck: [],
    };
  },
  methods: {
    desabilitarPresenca(item) {
      if (item.item?.faltaJustificada) {
        this.items[`${item.index}`].presente = false;
        this.faltaJustificadaIndex = item.index;
      } else if (!item.item?.faltaJustificada) {
        this.faltaJustificadaIndex = undefined;
      }
    },
    desabilitarJustificativa(item) {
      if (item.item?.presente) {
        this.items[`${item.index}`].faltaJustificada = false;
      }
    },
    marcarTodos() {
      for (const item of this.items) {
        item.presente = this.marcarTodasFrequencias;
      }
    },
  },
};
</script>
<style>
.position td {
  width: 800px;
  padding: 0px 5px;
}

.position thead {
  position: relative;
  max-width: 300px;
  z-index: 300;
}
.alerta {
  color: red;
  position: absolute;
  left: 50px;
}
.flex-text-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.position thead th:nth-child(2) {
  text-align: center;
}
.tophead th {
  background: pink;
}
.input .form-control {
  width: 200px;
}
</style>
