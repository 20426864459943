<template>
  <div>
    <b-table
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      :fields="tabela.fields"
      :items="items"
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <!--
                        REGRA DO DROPDOWN:
                        Aplicado somente para turma
                    -->
            <b-dropdown-group
              v-if="!somenteTurma(item.item)"
              id="dropdown-turmas"
              header="Somente para turmas:"
            >
              <b-dropdown-item
                :disabled="somenteTurma(item.item)"
                @click="parecerDescritivo(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_PARECER_DESCRITIVO') }}
              </b-dropdown-item>
            </b-dropdown-group>
            <!--
                        REGRA DOS DROPDOWNS ABAIXO:
                        Aplicado somente se estiver em uma disciplina
                    -->
            <b-dropdown-group
              v-if="!temDisciplina(item.item)"
              id="dropdown-disciplinas"
              header="Somente para disciplinas:"
            >
              <b-dropdown-item
                :disabled="temDisciplina(item.item)"
                @click="frequencia(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_FREQUENCIA') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="temDisciplina(item.item)"
                @click="nota(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_NOTA') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="temDisciplina(item.item)"
                @click="recuperacaoSegmentacao(item.item)"
              >
                {{ $t('ACADEMICO.RECUPERACAO_SEGMENTACAO') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="temDisciplina(item.item)"
                @click="pontosAdicionais(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_PONTO_ADICIONAL') }}
              </b-dropdown-item>

              <b-dropdown-text
                class="title-dropdown"
                @click="abriSubDropdownFrequencia"
              >
                <div class="d-flex align-items-center justify-content-between">
                  {{ $t('ACADEMICO.RELATORIO_REGISTRO_FREQUENCIA') }}
                  <img
                    :class="[
                      'dropdown-arrow',
                      {
                        'dropdown-arrow-reverse':
                          exibirSubDropDown.abrirFrequencia,
                      },
                    ]"
                    src="@/assets/images/arrow.svg"
                    alt="dropdown"
                  />
                </div>
              </b-dropdown-text>
              <b-dropdown-item
                class="title-dropdown-sub"
                v-show="exibirSubDropDown.abrirFrequencia"
                @click="imprimirRegistroFrequencia(item.item, true)"
              >
                {{ $t('ACADEMICO.RELATORIO_SEM_DADOS') }}
              </b-dropdown-item>
              <b-dropdown-item
                class="title-dropdown-sub"
                v-show="exibirSubDropDown.abrirFrequencia"
                @click="imprimirRegistroFrequencia(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_COM_DADOS') }}
              </b-dropdown-item>

              <b-dropdown-text
                class="title-dropdown"
                @click="abriSubDropdownAvaliacao"
              >
                <div class="d-flex align-items-center justify-content-between">
                  {{ $t('ACADEMICO.RELATORIO_REGISTRO_AVALIACAO') }}
                  <img
                    :class="[
                      'dropdown-arrow',
                      {
                        'dropdown-arrow-reverse':
                          exibirSubDropDown.abrirAvaliacao,
                      },
                    ]"
                    src="@/assets/images/arrow.svg"
                    alt="dropdown"
                  />
                </div>
              </b-dropdown-text>

              <b-dropdown-item
                class="title-dropdown-sub"
                v-show="exibirSubDropDown.abrirAvaliacao"
                @click="imprimirRegistroAvaliacao(item.item, true)"
              >
                {{ $t('ACADEMICO.RELATORIO_SEM_DADOS') }}
              </b-dropdown-item>
              <b-dropdown-item
                class="title-dropdown-sub"
                v-show="exibirSubDropDown.abrirAvaliacao"
                @click="imprimirRegistroAvaliacao(item.item)"
              >
                {{ $t('ACADEMICO.RELATORIO_COM_DADOS') }}
              </b-dropdown-item>

              <!-- RELATÓRIOS FECHAMENTO -->
              <!------ TITULO -->
              <!-- <b-dropdown-text
                class="title-dropdown"
                @click="abrirSubDropdownRelatoriosFechamento"
              >
                <div class="d-flex align-items-center justify-content-between">
                  {{ $t('ACADEMICO.RELATORIOS_FECHAMENTO') }}
                  <img
                    :class="[
                      'dropdown-arrow',
                      {
                        'dropdown-arrow-reverse':
                          exibirSubDropDown.abrirRelatoriosFechamento,
                      },
                    ]"
                    src="@/assets/images/arrow.svg"
                    alt="dropdown"
                  />
                </div>
              </b-dropdown-text> -->

              <!------ NOTAS E FALTAS -->
              <b-dropdown-item
                class="title-dropdown-sub"
                :disabled="temDisciplina(item.item)"
                v-show="exibirSubDropDown.abrirRelatoriosFechamento"
                @click="
                  imprimirRelatorioFechamento(
                    item.item,
                    'listagem-notas-faltas'
                  )
                "
              >
                {{ $t('ACADEMICO.RELATORIO_NOTAS_FALTAS') }}
              </b-dropdown-item>

              <!------ QUADRO ANALÍTICO -->
              <b-dropdown-item
                class="title-dropdown-sub"
                :disabled="temDisciplina(item.item)"
                v-show="exibirSubDropDown.abrirRelatoriosFechamento"
                @click="
                  imprimirRelatorioFechamento(item.item, 'quadro-analitico')
                "
              >
                {{ $t('ACADEMICO.RELATORIO_QUADRO_ANALITICO') }}
              </b-dropdown-item>

              <!------ QUADRO ANALÍTICO - SEGMENTACAO-->
              <b-dropdown-item
                class="title-dropdown-sub"
                :disabled="temDisciplina(item.item)"
                v-show="exibirSubDropDown.abrirRelatoriosFechamento"
                @click="
                  imprimirRelatorioFechamento(
                    item.item,
                    'quadro-analitico-segmentacao'
                  )
                "
              >
                {{ $t('ACADEMICO.RELATORIO_QUADRO_ANALITICO_SEGMENTACAO') }}
              </b-dropdown-item>

              <!------ MÉDIA FINAL E RECUPERAÇÃO -->
              <b-dropdown-item
                class="title-dropdown-sub"
                :disabled="temDisciplina(item.item)"
                v-show="exibirSubDropDown.abrirRelatoriosFechamento"
                @click="
                  imprimirRelatorioFechamento(
                    item.item,
                    'media-final-recuperacao'
                  )
                "
              >
                {{ $t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO') }}
              </b-dropdown-item>
              <!-- XX fim relatório fechamento XX -->
            </b-dropdown-group>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import mensagem from '@/common/utils/mensagem';

// Utils & Aux:
import { STOP_LOADING, START_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';

//Service
import PainelProfessorService from '@/common/services/painel-professor/painel-professor';

//components

export default {
  props: {
    items: {
      type: Array,
      default: Array,
    },
    parametroAnoLetivo: {
      type: String,
      default: String,
    },
  },
  data() {
    return {
      exibirSubDropDown: {
        abrirFrequencia: false,
        abrirAvaliacao: false,
        abrirRelatoriosFechamento: false,
      },
      tabela: {
        fields: [
          {
            key: 'cursoDescricao',
            label: 'Curso',
            sortable: true,
          },
          {
            key: 'turmaDescricao',
            label: 'Turma',
            sortable: true,
          },
          {
            key: 'disciplinaDescricao',
            label: 'Disciplina',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
      },
    };
  },
  methods: {
    abriSubDropdownFrequencia() {
      this.exibirSubDropDown.abrirFrequencia =
        !this.exibirSubDropDown.abrirFrequencia;
    },
    abriSubDropdownAvaliacao() {
      this.exibirSubDropDown.abrirAvaliacao =
        !this.exibirSubDropDown.abrirAvaliacao;
    },
    abrirSubDropdownRelatoriosFechamento() {
      this.exibirSubDropDown.abrirRelatoriosFechamento =
        !this.exibirSubDropDown.abrirRelatoriosFechamento;
    },
    imprimirRegistroFrequencia(item, semDadosLancados = false) {
      let objetoFormatado = {
        ...item,
        anoLetivoId: this.parametroAnoLetivo,
        semDadosLancados,
      };
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.realizarImpressaoRegistroFrequencia(
        objetoFormatado
      )
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    imprimirRegistroAvaliacao(item, semDadosLancados = false) {
      let objetoFormatado = {
        ...item,
        anoLetivoId: this.parametroAnoLetivo,
        semDadosLancados,
      };
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.realizarImpressaoRegistroAvaliacao(objetoFormatado)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimirRelatorioFechamento(item, rota) {
      let objetoFormatado = {
        ...item,
        anoLetivoId: this.parametroAnoLetivo,
        // isso não precisa, mas tenho que passar
        semDadosLancados: false,
      };
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.imprimirRelatoriosDeFechamento(
        objetoFormatado,
        rota
      )
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    frequencia(item) {
      this.$emit('frequencia', 'frequencia', item);
    },
    nota(item) {
      this.$emit('nota', 'nota', item);
    },
    recuperacaoSegmentacao(item) {
      this.$emit('recuperacaoSegmentacao', 'recuperacaoSegmentacao', item);
    },
    pontosAdicionais(item) {
      this.$emit('pontosAdicionais', 'pontosAdicionais', item);
    },
    parecerDescritivo(item) {
      this.$emit('parecerDescritivo', 'parecerDescritivo', item);
    },
    registroFrequencia(item) {
      this.$emit('registroFrequencia', 'registroFrequencia', item);
    },

    // FUNÇÕES DE HABILITAR/DESABILITAR:
    temDisciplina(item) {
      return item.disciplinaId == '00000000-0000-0000-0000-000000000000'
        ? true
        : false;
    },
    somenteTurma(item) {
      return item.disciplinaId != '00000000-0000-0000-0000-000000000000'
        ? true
        : false;
    },
  },
};
</script>

<style>
.title-dropdown,
.title-dropdown-sub {
  cursor: pointer;
  font-weight: bold;
  color: #272b34;
}

.title-dropdown-sub a {
  font-size: 12px;
  padding-left: 30px;
}

.title-dropdown:hover,
.title-dropdown:focus {
  background-color: #f8f9fa;
}

.dropdown-arrow {
  max-width: 8px;
}

.dropdown-arrow-reverse {
  transform: rotate(180deg);
}
</style>
