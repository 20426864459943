<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.PAINEL_PROFESSOR')"
      :subtitulo="$t('ACADEMICO.PAINEL_PROFESSOR_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />

    <filtro @filtrar="filtrar" @limparFiltro="filtrar" />

    <tabela
      class="mt-4"
      :items="resultadoBusca"
      :parametroAnoLetivo="parametroAnoLetivo"
      @recuperacaoSegmentacao="openModal"
      @frequencia="openModal"
      @nota="openModal"
      @pontosAdicionais="openModal"
      @parecerDescritivo="openModal"
    />

    <modal-frequencia
      :exibir="modais.frequencia"
      :form="modais.frequenciaDados"
      :filtro="filtro"
      @fechar="closeModal('frequencia')"
    />

    <modal-recuperacao-segmentacao
      :exibir="modais.recuperacaoSegmentacao"
      :form="modais.recuperacaoSegmentacaoDados"
      @fechar="closeModal('recuperacaoSegmentacao')"
    />

    <modal-nota
      :exibir="modais.nota"
      :form="modais.notaDados"
      :filtro="filtro"
      @fechar="closeModal('nota')"
    />

    <modal-pontos-adicionais
      :exibir="modais.pontosAdicionais"
      :form="modais.pontosAdicionaisDados"
      @fechar="closeModal('pontosAdicionais')"
    />

    <modal-parecer-descritivo
      :exibir="modais.parecerDescritivo"
      :form="modais.parecerDescritivoDados"
      @fechar="closeModal('parecerDescritivo')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Services:
import PainelProfessorService from '@/common/services/painel-professor/painel-professor.js';

// Components:
import Filtro from './components/Filtro.vue';
import Tabela from './components/Tabela.vue';
import ModalNota from './components/ModalNota/Modal.vue';
import ModalFrequencia from './components/ModalFrequencia/Modal.vue';
import ModalPontosAdicionais from './components/pontos-adicionais/ModalPontosAdicionais.vue';
import ModalRecuperacaoSegmentacao from '@/views/academico/painel-professor/components/ModalRecuperacaoSegmentacao/Index.vue';
import ModalParecerDescritivo from './components/parecer-descritivo/ModalParecerDescritivo.vue';

export default {
  components: {
    Topbar,
    Filtro,
    Tabela,
    ModalFrequencia,
    ModalNota,
    ModalPontosAdicionais,
    ModalRecuperacaoSegmentacao,
    ModalParecerDescritivo,
  },
  data() {
    return {
      resultadoBusca: [],
      filtro: {},
      parametroAnoLetivo: '',
      modais: {
        nota: false,
        notaDados: {},
        frequencia: false,
        frequenciaDados: {},
        pontosAdicionais: false,
        pontosAdicionaisDados: {},
        parecerDescritivo: false,
        parecerDescritivoDados: {},
        //recuperação por segmentacao
        recuperacaoSegmentacao: false,
        recuperacaoSegmentacaoDados: {},
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('PAINEL_PROFESSOR.PAINEL_PROFESSOR') },
      ],
    };
  },
  methods: {
    // FUNÇÕES FILTRO
    filtrar(filtroUtilizado) {
      this.parametroAnoLetivo = filtroUtilizado.anoLetivoId;

      if (filtroUtilizado?.limpeza) {
        this.resultadoBusca = [];
        return;
      }

      this.filtro = filtroUtilizado;
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.obterPorFiltro(filtroUtilizado)
        .then(({ data }) => {
          this.resultadoBusca = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
