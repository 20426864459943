<template>
  <div class="file-input">
    <label class="file-label">
        <span class="file-cta">
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        <span class="file-label">
          Escolha a imagem
        </span>
      </span>
      <input type="file" ref="input" @change="handleFileChange" accept="image/jpeg">
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputFoto',
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result;
          this.$emit('foto-selecionada', base64Data);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>