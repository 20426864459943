<template>
  <div>
    <modal
      :exibir="exibir"
      :titulo="`Parecer descritivo`"
      :campos="$refs"
      @fechar="fecharModal"
      id="modalParecerDescritivo"
      tamanho="lg"
      :ocultarRodape="!estadoBotoesModal"
    >
      <tabela-parecer-descritivo
        :form="dadosParecerDescritivo"
        :listarParecerDescritivo="listarParecerDescritivo"
        @botoesModal="botoesModal"
      />
      <!-- Footer do modal -->
      <template #modal-footer v-if="estadoBotoesModal">
        <b-button class="mr-2" variant="secondary" @click="fecharModal">{{
          $t('GERAL.FECHAR')
        }}</b-button>
      </template>
    </modal>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import PainelProfessorService from '@/common/services/painel-professor/painel-professor.js';

//Components
import Modal from '@/components/modal/Modal.vue';
import TabelaParecerDescritivo from './TabelaParecerDescritivo.vue';

export default {
  components: {
    Modal,
    TabelaParecerDescritivo,
  },
  watch: {
    exibir: 'listarParecerDescritivo',
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  data() {
    return {
      estadoBotoesModal: true,
      dadosParecerDescritivo: [],
    };
  },
  methods: {
    listarParecerDescritivo() {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.listarParecerDescritivo(this.form)
        .then(({ data }) => {
          this.dadosParecerDescritivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    fecharModal() {
      this.$emit('fechar');
      this.estadoBotoesModal = true;
    },

    botoesModal(estadoBotoesModal) {
      this.estadoBotoesModal = estadoBotoesModal;
    },
  },
};
</script>
