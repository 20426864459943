<template>
  <div>
    <div class="row">
      <div class="col-6">
        <input-date-only-btn
          ref="data"
          v-model="form.data"
          :label="$t('ACADEMICO.DATA')"
          required
        />
      </div>
      <div class="col-6">
        <input-text
          ref="descricao"
          v-model="form.descricao"
          type="text"
          :label="$t('ACADEMICO.AULA')"
          :placeholder="$t('ACADEMICO.AULA')"
          required
        />
      </div>
      <div class="col-12">
        <input-text
          class="ajuste-text-area"
          ref="conteudo"
          v-model="form.conteudo"
          type="text"
          :label="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
          :placeholder="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Components:
import { InputText, InputDateOnlyBtn } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
  components: {
    InputText,
    InputDateOnlyBtn,
  },
  methods: {
    voltar() {
      this.$emit('voltar');
    },
    salvar() {
      this.$emit('salvar');
    },
  },
};
</script>

<style>
td .input-group {
  height: auto !important;
  margin-top: 0px !important;
}

td textarea {
  margin-top: -5px;
  height: 40px !important;
}
</style>
