<template>
  <modal
    :exibir="exibir"
    :titulo="`Notas`"
    :campos="$refs"
    id="modalNotas"
    tamanho="xl"
    :ocultarRodape="true"
    @fechar="fecharModal"
  >
    <div v-if="tabelaAvaliacoes">
      <p>
        Para registrar uma nota, é necessário selecionar uma avaliação na tabela
        abaixo,
        <b>clicando no botão na coluna Ações</b> e selecionando a opção
        <b>Registrar nota</b>.
      </p>
      <p>
        Caso não exista a avalição que queira registrar nota, você pode criar
        uma aula por aqui mesmo clicando no botão abaixo:
      </p>

      <b-button variant="primary" class="mb-5" @click="criarAvaliacao">
        Nova avaliação
      </b-button>

      <tabela-avaliacoes
        :items="tabelas.dados.avaliacoes"
        v-if="tabelaAvaliacoes"
        @avaliacoes="registrarNotas"
        @atualizar="getAvaliacoes"
      />
    </div>
    <nova-avaliacao
      v-if="novaAvaliacao"
      :dadosGerais="form"
      :filtro="filtro"
      @salvar="salvarNovaAvaliacao"
      @cancelar="voltarAvaliacoes"
    />
    <div v-if="tabelaNotas">
      <tabela-nota
        :items="tabelas.dados.notas"
        @atualizado="getNotas"
        :tipoAvaliacaoCodigo="tipoAvaliacaoCodigo"
      />
      <div class="w-100 d-flex justify-content-between">
        <b-button @click="voltarAvaliacoes" variant="secondary" class="mr-2">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
        <b-button @click="salvarAtualizar" variant="primary" class="mr-2">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </div>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services & Models:
import AvaliacoesService from '@/common/services/academico/avaliacoes.service.js';
import NotaService from '@/common/services/academico/nota.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import TabelaAvaliacoes from './TabelaAvaliacoes.vue';
import TabelaNota from './TabelaNota.vue';
import NovaAvaliacao from './NovaAvaliacao.vue';
export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    filtro: { type: Object, default: Object },
  },
  components: {
    Modal,
    TabelaAvaliacoes,
    TabelaNota,
    NovaAvaliacao,
  },
  data() {
    return {
      tabelas: {
        exibir: {
          avaliacoes: true,
          notas: false,
        },
        dados: {
          avaliacoes: [],
          notas: [],
        },
      },
      novaAvaliacao: false,
      dadosNotas: [],
      tipoAvaliacaoCodigo: 0,
    };
  },
  watch: {
    exibir() {
      this.exibir ? this.configuracaoInicial() : {};
    },
  },
  computed: {
    tabelaAvaliacoes() {
      return this.tabelas.exibir.avaliacoes;
    },
    tabelaNotas() {
      return this.tabelas.exibir.notas;
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DADOS:

    getNotas(notasAtualizadas) {
      this.dadosNotas = notasAtualizadas;
    },
    getAvaliacoes() {
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.obterPorFiltro(this.form)
        .then(({ data }) => {
          this.tabelas.dados.avaliacoes = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obterNotas(item) {
      let filtro = {
        anoLetivoId: this.filtro.anoLetivoId,
        cursoId: this.form.cursoId,
        turmaId: item.turmaId,
        disciplinaId: item.disciplinaId,
        avaliacaoId: item.id,
      };

      this.$store.dispatch(START_LOADING);
      NotaService.obterPorFiltro(filtro)
        .then(({ data }) => {
          this.tabelas.dados.notas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    registrarNotas(keyHide, keyShow, item) {
      this.tipoAvaliacaoCodigo = item.tipoAvaliacao.codigo;
      this.obterNotas(item);
      this.openTabela(keyHide, keyShow, item);
    },
    // FUNÇÕES MODAL
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES TABELAS
    openTabela(keyHide, keyShow, item) {
      this.tabelas.exibir[keyHide] = false;
      this.tabelas.exibir[keyShow] = true;

      item ? this.tabelas.dados[keyShow] : {};
    },
    // FUNÇÕES CRUD
    criarAvaliacao() {
      this.tabelas.exibir.avaliacoes = false;
      this.tabelas.exibir.notas = false;
      this.novaAvaliacao = true;
    },
    voltarAvaliacoes() {
      this.tabelas.exibir.avaliacoes = true;
      this.tabelas.exibir.notas = false;
      this.novaAvaliacao = false;
      this.getAvaliacoes();
    },
    salvarNovaAvaliacao() {
      this.voltarAvaliacoes();
    },
    salvarAtualizar() {
      let padraoNuloId = '00000000-0000-0000-0000-000000000000';

      const criacao = [];
      const atualizar = [];

      this.dadosNotas.forEach((item) => {
        item.id === padraoNuloId ? criacao.push(item) : atualizar.push(item);
      });

      criacao.length ? this.criar(criacao) : {};
      atualizar.length ? this.atualizar(atualizar) : {};
    },
    criar(form) {
      this.$store.dispatch(START_LOADING);
      NotaService.criar(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Pontos atualizados com sucesso')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    atualizar(form) {
      this.$store.dispatch(START_LOADING);
      NotaService.editar(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Pontos atualizados com sucesso')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES AUXILIARES:
    configuracaoInicial() {
      this.tabelas.exibir.avaliacoes = true;
      this.tabelas.exibir.notas = false;
      this.novaAvaliacao = false;
      this.getAvaliacoes();
    },
  },
};
</script>
