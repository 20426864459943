import ApiService from "@/common/api/api.service";

let apiBasePath = 'Frequencia'
const FrequenciaService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    // FUNÇÕES FILTRO:
    async obterPorFiltro(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
        {
            params: {
                ...filtro
            },
        }
        );
        return data;
    },
}

export default FrequenciaService;