import ApiService from "@/common/api/api.service";

let apiBasePath = 'Nota'
const NotaService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    // FUNÇÕES FILTRO
    async obterPorFiltro(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
        {
            params: {
                TurmaId:filtro.turmaId,
                DisciplinaId:filtro.disciplinaId,
                AulaId:filtro.aulaId,
                MatriculaId:filtro.matriculaId,
                CursoId:filtro.cursoId,
                AnoLetivoId:filtro.anoLetivoId,
                AvaliacaoId:filtro.avaliacaoId
            },
        }
        );
        return data;
    },
}

export default NotaService;