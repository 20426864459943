<template>
  <div>
    <b-table
      v-if="tabela"
      :items="form"
      :fields="campos"
      show-empty
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      responsive
      sticky-header="400px"
      head-variant="light"
      hover
    >
      <template #cell(adicionar)="item">
        <template>
          <b-dropdown variant="outline-default">
            <b-dropdown-item
              v-if="!item.item.conteudo"
              @click="adicionarInfo(item)"
            >
              {{ $t('GERAL.ADICIONAR') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.item.conteudo"
              @click="adicionarInfo(item)"
            >
              {{ $t('GERAL.EDITAR') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.item.conteudo"
              @click="excluirInfo(item.item.id)"
            >
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </template>
    </b-table>

    <div v-else>
      <b-row class="flex-column flex-sm-row">
        <b-col cols="auto" class="col-sm-6">
          <input-text
            ref="nome"
            v-model="formSelecionado.nomeAluno"
            label="Aluno"
            :disabled="true"
          />
        </b-col>
        <b-col cols="auto" class="col-sm-6">
          <input-text
            ref="dataLancamento"
            :disabled="true"
            :value="dataLancamentoFormatado"
            label="Data de lançamento:"
          />
        </b-col>
        <div class="container-photo">
          <input-foto class="mt-3" @foto-selecionada="arquivoSelecionado" />
        </div>
        <b-col cols="auto" class="col-sm-12 mt-3">
          <b-button
            class="mr-2 mb-2"
            @click="visualizarArquivo"
            variant="primary"
            >Visualizar arquivo</b-button
          >
          <p>Arquivo pré-carregado: {{ formSelecionado.arquivo }}</p>
        </b-col>
        <b-col cols="auto" class="col-sm-12 mt-4">
          <input-editor-text
            ref="parecer"
            v-model="formSelecionado.conteudo"
            :value="formSelecionado.conteudo"
            label="Parecer:"
            required
          ></input-editor-text>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-between">
        <b-button class="mr-2" variant="secondary" @click="voltarTabela">{{
          $t('GERAL.VOLTAR')
        }}</b-button>
        <b-button variant="primary" @click="salvarAtualizar">{{
          $t('GERAL.SALVAR')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import { NIL as NIL_UUID } from 'uuid';

// Services:
import PainelProfessorService from '@/common/services/painel-professor/painel-professor.js';
// Components:
import { InputText, InputEditorText } from '@/components/inputs';
import InputFoto from '@/components/foto/InputFoto.vue';

export default {
  components: {
    InputText,
    InputEditorText,
    InputFoto,
  },
  props: {
    form: { type: Array, default: Array },
    listarParecerDescritivo: { type: Function },
  },

  computed: {
    dataLancamentoFormatado() {
      const data = helpers.formatarDataBr(this.formSelecionado.dataLancamento);
      return data;
    },
  },

  data() {
    return {
      campos: [
        {
          key: 'nomeAluno',
          sortably: true,
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.ALUNO'),
        },
        {
          sortably: true,
          key: 'adicionar',
          label: 'Ações',
        },
      ],

      tabela: true,
      formSelecionado: {},
      photoUrl: null,
      nomeArquivo: '',
      tipoArquivo: '',
      tiposDeArquivo: ['jpeg', 'pdf', 'png', 'txt', 'docx'],
    };
  },
  methods: {
    buscarArquivoExistente(id) {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.recuperarArquivo(id)
        .then(({ data }) => {
          this.photoUrl = data.documento;
          this.tipoArquivo = data.informacaoExtra;
          this.nomeArquivo = data.nomeArquivo;
        })
        .catch(() => {
          // mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    arquivoSelecionado(event) {
      this.photoUrl = event;
      this.tipoArquivo = '';
      this.formSelecionado.arquivo = null;
    },
    adicionarInfo(alunoSelecionado) {
      const { item, index } = alunoSelecionado;
      this.formSelecionado = { ...item, index };

      if (item.arquivo != '') {
        this.buscarArquivoExistente(item.id);
      }

      this.tabela = false;
      this.$emit('botoesModal', false);
    },
    excluirInfo(id) {
      mensagem
        .confirmacao(null, this.$t('Deseja remover o Parecer Descritivo? ?'))
        .then(async (result) => {
          if (result.value) {
            if (id) {
              this.excluir(id);
              return;
            }
          }
        });
    },
    excluir(id) {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.excluirParecerDescritivo(id)
        .then(() => {
          this.listarParecerDescritivo();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async salvarAtualizar() {
      if (this.formSelecionado.conteudo.trim() === '')
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('PAINEL_PROFESSOR.CAMPO_PARECER_OBRIGATORIO')
        );
      const padraoNuloId = NIL_UUID;

      if (this.formSelecionado.arquivo?.src?.result) {
        this.formSelecionado.arquivo = this.formSelecionado.arquivo.src.result;
      }

      if (this.formSelecionado.id === padraoNuloId) {
        this.salvar(this.formSelecionado);
      } else {
        this.atualizar(this.formSelecionado);
      }

      this.voltarTabela();

      mensagem.sucesso(
        this.$t('GERAL.SUCESSO'),
        this.$t('Parecer atualizado com sucesso')
      );
    },

    tratarTipoArquivo() {
      if (this.photoUrl) {
        let fraseAlvo = 'base64,';

        let base = '';
        let indice2 = this.photoUrl.indexOf(fraseAlvo);

        if (indice2 !== -1) {
          base = this.photoUrl.slice(0, indice2 + fraseAlvo.length);
          base = base.replace('data:', '').replace(';base64,', '');
        }

        let indice = this.photoUrl.indexOf(fraseAlvo);

        this.photoUrl = this.photoUrl.substring(
          indice + fraseAlvo.length,
          this.photoUrl.length
        );

        var tipoArquivo;

        this.tiposDeArquivo.forEach((tipo) => {
          if (base.includes(tipo)) {
            tipoArquivo = tipo;
            return;
          }
        });

        return {
          uploadArquivo: this.photoUrl,
          arquivo: `${this.formSelecionado.nomeAluno}${
            tipoArquivo ? '.' + tipoArquivo : ''
          }`,
          tipoArquivo: base,
        };
      }
    },
    salvar(form) {
      if (this.photoUrl) {
        const { uploadArquivo, arquivo, tipoArquivo } =
          this.tratarTipoArquivo();

        form.UploadArquivo = uploadArquivo;
        form.tipoArquivo = tipoArquivo;
        form.arquivo = arquivo;
      } else {
        form.UploadArquivo = null;
        form.tipoArquivo = null;
        form.arquivo = null;
      }

      this.$store.dispatch(START_LOADING);
      PainelProfessorService.criarParecerDescritivo(form)
        .then(() => {
          this.listarParecerDescritivo();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.photoUrl = null;
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizar(form) {
      if (this.photoUrl) {
        const { uploadArquivo, tipoArquivo, arquivo } =
          this.tratarTipoArquivo();
        form.UploadArquivo = uploadArquivo;
        form.tipoArquivo = tipoArquivo;

        if (this.formSelecionado.arquivo === null) {
          form.arquivo = arquivo;
        }
      } else {
        form.UploadArquivo = null;
        form.tipoArquivo = null;
        form.arquivo = null;
      }

      this.$store.dispatch(START_LOADING);
      PainelProfessorService.atualizarParecerDescritivo(form)
        .then(() => {
          this.listarParecerDescritivo();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.photoUrl = null;
          this.$store.dispatch(STOP_LOADING);
        });
    },
    visualizarArquivo() {
      if (this.photoUrl) {
        let base64Data =
          this.tipoArquivo !== ''
            ? `data:${this.tipoArquivo};base64,${this.photoUrl}`
            : this.photoUrl;

        const blob = this.base64toBlob(base64Data);

        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');
      }
    },
    base64toBlob(base64Data) {
      const parts = base64Data.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uint8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uint8Array], { type: contentType });
    },
    voltarTabela() {
      this.tabela = true;
      this.$emit('botoesModal', true);
    },
  },
};
</script>

<style>
.margin {
  margin: 0;
}
</style>
