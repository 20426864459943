<template>
  <b-table
    :fields="fields"
    :items="items"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    responsive
    head-variant="light"
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="notas(item.item)">
          {{ $t('PAINEL_PROFESSOR.REGISTRAR_NOTAS') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('Excluir') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import helpers from '@/common/utils/helpers';
import AvaliacoesService from '@/common/services/academico/avaliacoes.service.js';

import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'dataAvaliacao',
          label: 'Data',
          sortable: true,
          formatter: (value) => {
            return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'descricao',
          label: 'Avaliação',
          sortable: true,
        },
        {
          key: 'tipoAvaliacao.descricao',
          label: 'Tipo',
          sortable: true,
        },
        {
          key: 'recuperacao',
          label: 'Recuperação',
          sortable: true,
          formatter: (value) => {
            return value ? 'Sim' : 'Não';
          },
        },
        {
          key: 'dataRecuperacao',
          label: 'Data da Recuperação',
          sortable: true,
          formatter: (value) => {
            return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    notas(item) {
      this.$emit('avaliacoes', 'avaliacoes', 'notas', item);
    },

    excluir(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja excluir avaliação ?'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            AvaliacoesService.excluir(item.id)
              .then(() => {
                this.$emit('atualizar');
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('Avaliação excluída com sucesso')
                );
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
  },
};
</script>
