<template>
  <modal
    id="modalRecuperacaoSegmentacao"
    :exibir="exibir"
    titulo="ACADEMICO.RECUPERACAO_SEGMENTACAO"
    :campos="$refs"
    :tamanho="'lg'"
    @fechar="fecharModal"
  >
    <tabela-recuperacao-segmentacao
      :form="dadosRecuperacaoSegmentacao"
      @atualizado="atualizarForm"
    />

    <!-- Footer do modal -->
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button class="mr-2" variant="secondary" @click="fecharModal">{{
          $t('GERAL.FECHAR')
        }}</b-button>
        <b-button variant="primary" @click="criar">{{
          $t('GERAL.SALVAR')
        }}</b-button>
      </div>
    </template>
  </modal>
</template>
  <script>
// Components
import Modal from '@/components/modal/Modal.vue';
import TabelaRecuperacaoSegmentacao from '@/views/academico/painel-professor/components/ModalRecuperacaoSegmentacao/TabelaRecuperacaoSegmentacao.vue';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import PainelProfessorService from '@/common/services/painel-professor/painel-professor.js';

export default {
  components: {
    Modal,
    TabelaRecuperacaoSegmentacao,
  },
  watch: {
    exibir: 'listarDadosRecuperacaoSegmentacao',
  },
  props: {
    exibir: { type: Boolean, default: false, sortable: true },
    form: { type: Object, default: Object, sortable: true },
  },
  data() {
    return {
      dadosRecuperacaoSegmentacao: [],
    };
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    listarDadosRecuperacaoSegmentacao() {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.listarDadosRecuperacaoSegmentacao(this.form)
        .then(({ data }) => {
          this.dadosRecuperacaoSegmentacao = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    atualizarForm(dadosRecuperacaoSegmentacao) {
      this.dadosRecuperacaoSegmentacao = dadosRecuperacaoSegmentacao;
    },

    // criar recuperacao segmentacao
    criar() {
      this.$store.dispatch(START_LOADING);
      PainelProfessorService.criarListaRecuperacaoSegmentacao(
        this.dadosRecuperacaoSegmentacao
      )
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
  