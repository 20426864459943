import ApiService from '@/common/api/api.service';

let apiBasePath = 'PainelProfessor';

const PainelProfessorService = {
  // FUNÇÕES RECUPERACAO POR SEGMENTACAO
  async listarDadosRecuperacaoSegmentacao(form) {
    let { data } = await ApiService.get(
      `${apiBasePath}/recuperacao-segmentacao/listar`,
      {
        params: {
          ...form,
        },
      }
    );
    return data;
  },

  async criarListaRecuperacaoSegmentacao(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/recuperacao-segmentacao/criar-lista`,
      form
    );
    return data;
  },

  // FUNÇÕES CRUD PARECER DESCRITIVO

  async excluirParecerDescritivo(id) {
    let { data } = await ApiService.delete(`${apiBasePath}/parecer-descritivo/remover?parecerDescritivoId=${id}`);
    return data;
  },
  async criarParecerDescritivo(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/parecer-descritivo/criar`,
      form
    );
    return data;
  },
  async criarListaParecerDescritivo(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/parecer-descritivo/criar-lista`,
      form
    );
    return data;
  },

  async atualizarParecerDescritivo(form) {
    let { data } = await ApiService.put(
      `${apiBasePath}/parecer-descritivo/atualizar`,
      form
    );
    return data;
  },
  async atualizarListaParecerDescritivo(form) {
    let { data } = await ApiService.put(
      `${apiBasePath}/parecer-descritivo/atualizar-lista`,
      form
    );
    return data;
  },

  async listarParecerDescritivo(form) {
    let { data } = await ApiService.get(
      `${apiBasePath}/parecer-descritivo/listar`,
      {
        params: {
          SegmentacaoId: form.segmentacaoId,
          TurmaId: form.turmaId,
        },
      }
    );
    return data;
  },

  async realizarImpressaoRegistroFrequencia(form) {
    let { data } = await ApiService.get(
      `Frequencia/imprimir-relatorio-frequencia`,
      {
        params: {
          SegmentacaoId: form.segmentacaoId,
          TurmaId: form.turmaId,
          AnoLetivoId: form.anoLetivoId,
          CursoId: form.cursoId,
          DisciplinaId: form.disciplinaId,
          SemDadosLancados: form.semDadosLancados,
        },
      }
    );
    return data;
  },

  async realizarImpressaoRegistroAvaliacao(form) {
    let { data } = await ApiService.get(
      `Avaliacao/imprimir-relatorio-avaliacao`,
      {
        params: {
          SegmentacaoId: form.segmentacaoId,
          TurmaId: form.turmaId,
          AnoLetivoId: form.anoLetivoId,
          CursoId: form.cursoId,
          DisciplinaId: form.disciplinaId,
          SemDadosLancados: form.semDadosLancados,
        },
      }
    );
    return data;
  },

  // FUNÇÕES CRUD PONTOS ADICIONAIS
  async criarPontosAdicionais(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/ponto-adicional/criar`,
      form
    );
    return data;
  },
  async criarListaPontosAdicionais(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/ponto-adicional/criar-lista`,
      form
    );
    return data;
  },

  async atualizarPontosAdicionais(form) {
    let { data } = await ApiService.put(
      `${apiBasePath}/ponto-adicional/atualizar`,
      form
    );
    return data;
  },
  async atualizarListaPontosAdicionais(form) {
    let { data } = await ApiService.put(
      `${apiBasePath}/ponto-adicional/atualizar-lista`,
      form
    );
    return data;
  },

  async listarPontosAdicionais(form) {
    let { data } = await ApiService.get(
      `${apiBasePath}/ponto-adicional/listar`,
      {
        params: {
          SegmentacaoId: form.segmentacaoId,
          disciplinaId: form.disciplinaId,
          TurmaId: form.turmaId,
        },
      }
    );
    return data;
  },

  // FUNÇÕES FILTRO
  async obterPorFiltro(filtro) {
    let { data } = await ApiService.get(`${apiBasePath}/listar-por-filtro`, {
      params: {
        AnoLetivoId: filtro.anoLetivoId,
        SegmentacaoId: filtro.segmentacaoId,
        CursoId: filtro.cursoId,
        TurmaId: filtro.turmaId,
        DisciplinaId: filtro.disciplinaId,
      },
    });
    return data;
  },

  // RELATÓRIO DE FECHAMENTO
  async imprimirRelatoriosDeFechamento(filtro, rota) {
    let { data } = await ApiService.get(
      `${apiBasePath}/relatorio-fechamento/${rota}`,
      {
        params: { ...filtro },
      }
    );
    return data;
  },
  async recuperarArquivo(id) {
    let { data } = await ApiService.get(
      `${apiBasePath}/recuperar-arquivo`,
      {
        params: { id: id }
      }
    );
    return data;
  },
};

export default PainelProfessorService;
